.intro-wrap {
  font-family: "Roboto";
  width: 100%;
  position: fixed;
  letter-spacing: 0.15em;
  font-size: 15.22px;
  touch-action: pan-x pan-y;
  z-index: 1;
}

.intro-wrap a {
  font-family: "Roboto";
  font-weight: bold;
  color: black;
}

.container {
  position: absolute;
  width: 100%;
  height: 100vh;

  border-radius: 4px;
  background: #fff;
  z-index: 100;
  display: flex;
  align-items: center;
}

.loading {
  position: relative;
  display: inline-block;
  width: 100%;
  width: 100vw;
  margin-left: 80px;
  margin-right: 80px;
  height: 10px;
  background: #f1f1f1;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  overflow: hidden;
}

.loadingfill {
  content: "";
  position: absolute;
  left: 0;
  width: 50%;
  height: 10px;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  /* animation: load 5s infinite; */
  background: #000;
}
.footerContainer {
  position: relative;
  min-height: 100vh;
  z-index: 101;
  display: flex;
  width: 100%;
  background: none;
  align-items: center;
  justify-content: center;
}

.footer {
  position: fixed;
  width: auto;
  bottom: 11px;
  background-color: #fff;
  border-radius: 6px;
  text-align: center;
  /* padding: 10px; */
  box-sizing: border-box;
  margin: 0 auto;
}

.footerContainerMobile {
  position: relative;
  min-height: 100vh;
  z-index: 101;
  display: flex;
  flex-shrink: 1;
  width: 100%;
  background: none;
  justify-content: center;
  align-content: center;
  width: 100vw;
}

.footerMobile {
  position: absolute;
  justify-self: center;
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-content: center;
  margin: auto;
  width: 310px;
  height: 128px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.footerText {
  padding-left: 20px;
  padding-right: 20px;
  text-transform: uppercase;
  justify-self: center;
  align-self: center;
  text-align: center;
  font-size: 16px;
  line-height: 155%;
  letter-spacing: 0.1em;
  color: #000000;
}
.footerText a:hover {
  color:#ED1E23;
}
.fade {
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*SPINNER*/

.spinner {
  width: 60px;
  height: 60px;
  background-color: #ed1e23;

  margin: 100px auto;
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
}

@-webkit-keyframes sk-rotateplane {
  0% {
    -webkit-transform: perspective(120px);
  }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
