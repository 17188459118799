@import-normalize;

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;900&display=swap");

/* SHARED */

html {
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  font-weight: 400;
  font-style: normal;
  line-height: 130%;
}

body {
  background-color: #fff;
}

input,
button {
}

a,
a:active,
a:visited {
  color: #000;
}

a:hover {
}

#root {
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

#root .content {
  /* width: 100%;
  min-height: 90vh; */
}

.content-container {
  width: 100%;
}

main {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  max-width: 1170px;
  min-height: 38vh;
}

.heading {
  font-family: Roboto;
  font-size: 72px;
  font-style: normal;
  font-weight: 500;
  line-height: 72px;
  letter-spacing: 0em;
  text-transform: uppercase;
  margin-top: 80px;
  width: 100%;
  margin-bottom: 64px;
}

.heading .red-heading {
  color: #ed1e23;
}

@media only screen and (max-width: 1200px) {
  html {
  }
}

@media only screen and (max-width: 900px) {
  html {
  }
  .heading {
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    margin-top: 40px;
    margin-bottom: 32px;
    width: 98%;
    margin: 62px auto 52px auto;
  }
}

@media only screen and (max-width: 768px) {
  html {
  }
}

@media only screen and (max-width: 480px) {
  html {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }
  .heading {
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    margin-top: 40px;
    margin-bottom: 32px;
    width: 98%;
    margin: 62px auto 52px auto;
  }
}

/* END SHARED */

/*START HEADER*/

header {
  display: flex;
  max-width: 100%;
  justify-content: center;
  flex-wrap: wrap;
}

.header-logo {
  width: 100%;
  text-align: center;
  margin-bottom: 50px;
  margin-top: 60px;
}

.header-logo img {
  width: 670px;
}

.header-nav {
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
}

.header-nav a {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  margin: 0 13px;
  text-decoration: none;
  display: inline-block;
  padding: 15px;
}

.header-nav a.active {
  box-shadow: 0px 0px 10px 0px #00000026;
  color: #ed1e23;
}

.header-nav br {
  display: none;
}

@media only screen and (max-width: 1200px) {
  header {
  }
}

@media only screen and (max-width: 768px) {
  .header-logo img {
    width: 90%;
  }
}

@media only screen and (max-width: 480px) {
  header {
  }

  .header-logo {
    margin-top: 40px;
  }

  .header-nav {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
  }

  .header-nav span {
    flex: 1;
    margin-bottom: 10px;
  }

  .header-nav a {
    margin: 0;
  }

  /* .header-nav a {
    margin-top: 10px;
  }
  .header-nav a:first-child {
    margin-top: 0;
  }
  .header-nav br {
    display: initial;
  } */
}

/*END HEADER*/

/*START FOOTER*/

footer {
  background-color: #000;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  line-height: 22px;
  letter-spacing: 0.15em;
  padding-top: 60px;
  padding-bottom: 60px;
  margin-top: 130px;
  text-transform: uppercase;
}

.footer-wrap {
  max-width: 1170px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

.footer-item {
  padding: 0 30px;
}

.footer-wrap a {
  color: #fff;
  text-decoration: none;
  line-height: 32px;
}

.footer-wrap .footer-social-icon img {
  vertical-align: middle;
  margin-left: 24px;
  height: 27px;
}
/* .footer-wrap .footer-item.mobile-social .footer-social-icon img {
  display: none;
} */
.footer-wrap .footer-item.mobile-social {
  display: none;
}

@media only screen and (max-width: 1200px) {
  footer {
    max-width: 100%;
  }
}

@media only screen and (max-width: 900px) {
  footer {
    margin-top: 110px;
  }
  .footer-wrap {
    flex-direction: column;
    align-items: center;
  }

  .footer-item {
    margin-bottom: 37px;
  }

  .footer-wrap .footer-social-icon img {
    margin-left: 10px;
    display: none;
  }

  .footer-wrap .footer-item.mobile-social {
    display: initial;
  }
  .footer-wrap .footer-item.mobile-social .footer-social-icon img {
    margin-left: 10px;
    display: inline-block;
  }

  .footer-wrap a {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.15em;
  }
}

/*END FOOTER*/

/*WE PAGE*/

.we-wrap p {
  margin-top: 0;
  margin-bottom: 0;
}

.we-wrap .we-hero {
  width: 100%;
}

.we-wrap .we-text {
  margin-right: 14%;
}

.icon-grid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.icon-grid-item {
  width: 50%;
  display: flex;
  align-items: center;
  margin-bottom: 44px;
}

.icon-grid-item img {
  margin-right: 6px;
}

.team-grid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 475px;
}
#diversity-animation {
  width: 100%;
  /* display: inline; */
}
.team-grid-section {
  width:50%;
}

.team-grid-section:nth-child(1) > div {
  margin-right: 20px;
}

.team-grid-section:nth-child(2) > div {
  margin-left: 23px;
}

.team-percentage {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 120%;
  display: inline-block;
  width: 155px;
}

.team-percentage-description {
  position: relative;
  bottom: 0px;
  left:24px;
  font-size: 32px;
}

.team-stats {
  margin-bottom:13px;
}

#deiw {
  margin-bottom: 20px;
  display: flex;


}

#team {
  border: 1px black solid;
  height: 134px;
  font-weight: 500;
  font-size: 20px;
  line-height: 135%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-bottom: 30px;
}

#success {
  margin-bottom: 21px;
  line-height: 120%;
}

@media only screen and (max-width: 900px) {
  .team-grid  {
    max-height: unset;
    height: unset;
    justify-content: center;
    align-items: center;
  }
  .team-percentage {
    width: unset;
  }
  .team-grid-section {
    width: 80%;
  }
  .icon-grid {
    flex-direction: column;
  }
  .icon-grid-item {
    width: 100%;
  }
  .team-percentage-descriptio {
    left: 0;
  }

  .we-wrap p {
    width: 98%;
    margin: 0 auto;
  }
  .we-wrap .we-text {
  }
}

@media only screen and (max-width: 480px) {
  .team-grid-section:nth-child(1) > div {
    margin-right: 0px;
  }
  .team-grid-section:nth-child(2) > div {
    margin-left: 0px;
  }

  .we-wrap {
    margin: 65px 10px 0 10px;
  }
  .we-wrap .we-text {
    margin-right: 0;
  }
  .icon-grid-item {
    width: 100%;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    margin-bottom: 24px;
  }
  .icon-grid-item img {
    width: 54.5px;
  }
  
  .we-wrap > .heading {
  }
}

/*END WE PAGE*/

/*DIGITAL PHYSICAL PAGE*/

.link-grid-wrap {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.link-grid-item {
  width: 380px;
  height: 260px;
  margin-right: 15px;
  position: relative;
  margin-bottom: 15px;
}

.link-grid-item.link-grid-item-end-3 {
  margin-right: 0;
}

.link-grid-item img {
  width: 100%;
  display: block;
}

.item-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(235, 35, 45, 0.85);
  text-align: center;
  opacity: 0;
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease;
  opacity: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  line-height: 100%;
}

.item-overlay:hover {
  opacity: 1;
}

.item-overlay a {
  text-decoration: none;
  transform: translateY(-20px);
  transition: all 0.6s ease;
  font-size: 18px;
  font-weight: 500;
  line-height: 130%;
  margin-left: 10px;
  margin-right: 10px;
}

.item-overlay:hover a {
  transform: translateY(0px);
}

.item-line {
  display: block;
  margin-bottom: 11px;
  padding-bottom: 10px;
  position: relative;
  width: 100%;
}

.item-line::after {
  position: absolute;
  content: "";
  width: 45px;
  border-bottom: 2px solid #ffffff;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

@media only screen and (max-width: 1200px) {
  .link-grid-item {
    margin: 0 0 15px 0;
    width: 47%;
    height: auto;
  }
  .icon-grid-item {
  }
}

@media only screen and (max-width: 480px) {
  .link-grid-wrap {
    margin: 38px 10px 0 10px;
  }
  .link-grid-item {
    margin: 0 0 15px 0;
    width: 100%;
    height: auto;
  }
  .icon-grid-item {
  }
}

/*END DIGITAL PHYSICAL PAGE*/

/* PROJECT PAGE */

.proj-wrap {
  width: 100%;
  margin-bottom: 100px;
}

.proj-header-wrap {
  padding-left: 10px;
  padding-right: 10px;
}

.proj-header {
  width: 100%;
  padding: 10px 0;
  margin-bottom: 10px;
  text-transform: uppercase;

  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 56px;
  letter-spacing: 1.5517239570617676px;
  text-align: left;
}

.proj-client {
  color: #ed1e23;
  display: inline-block;
  margin-right: 35px;
}

.proj-name {
  display: inline-block;
}

.proj-hero {
  width: 100%;
  margin-bottom: 25px;
}

.proj-featured {
  margin-left: 10px;
  margin-right: 10px;
  box-sizing: border-box;
}

.proj-featured img {
  width: 100%;
  display: block;
}

.proj-m-featured {
  display: none;
}

.proj-text {
  box-sizing: border-box;
  margin: 36.5px 10px 40px 10px;
  font-size: 24px;
  font-weight: 500;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
}

.proj-stats {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;
  margin-right: 10px;
  color: #a2a2a2;
}

.stat {
  text-align: left;
  margin-right: 25px;
  margin-bottom: 25px;
}
.stat-num {
  font-size: 34px;
  line-height: 100%;
}
.stat-name {
  font-size: 15px;
  line-height: 100%;
}

.gallery {
  width: 100%;
  flex-wrap: wrap;
  display: flex;
}
.gallery-item {
  box-sizing: border-box;
  float: left;
  padding: 0 10px 20px 10px;
}

.gallery-item.full {
  width: 100%;
}

.gallery-item.half {
  width: 50%;
}
.gallery-item.onethird {
  width: 33.33%;
}

.gallery-item.twothird {
  width: 66.67%;
}

.gallery-item img {
  display: block;
  width: 100%;
  height: auto;
}
.gallery-item span {
  display: block;
  width: 100%;
  height: auto;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: 1.55172px;
  text-transform: uppercase;
  margin-top: 24px;

}

@media only screen and (max-width: 768px) {
  .proj-hero {
    flex-direction: column;
  }
  .proj-featured {
    margin-right: 0;
    padding-right: 10px;
  }
  .proj-text {
    margin-left: 10px;
    margin-top: 25px;
  }
  .gallery-item,
  .gallery-item.half,
  .gallery-item.onethird,
  .gallery-item.twothird {
    width: 100%;
    padding: 0 0 20px 0;
  }

  .gallery-item img {
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media only screen and (max-width: 480px) {
  .proj-header {
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 1.5517239570617676px;
    text-align: left;
  }

  .proj-m-featured {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 50px;
  }

  .proj-m-featured img {
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
  }

  .proj-featured {
    display: none;
  }

  .proj-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    margin: 14px 0 30px 10px;
  }
}

/* END PROJECT PAGE */

/*EXAMPLES*/

/* .hero-callout {
  background-image: url(../assets/bg-arrow.png);
}*/

/* styles for Retina-type displays */
/*
@media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 900px),
  only screen and (min--moz-device-pixel-ratio: 2) and (min-width: 900px),
  only screen and (-moz-min-device-pixel-ratio: 2) and (min-width: 900px),
  only screen and (-o-min-device-pixel-ratio: 2/1) and (min-width: 900px),
  only screen and (min-device-pixel-ratio: 2) and (min-width: 900px),
  only screen and (min-resolution: 192dpi) and (min-width: 900px),
  only screen and (min-resolution: 2dppx) and (min-width: 900px) {
 
  .slide0 {
    background-image: url("../assets/slide0@2x.png");
  }
  .slide1 {
    background-image: url("../assets/slide1@2x.png");
  }
}*/



.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}